<template>
  <table class="table table-striped" style="table-layout: fixed">
    <thead>
    <tr class="text-center">
      <th>อาทิตย์</th>
      <th>จันทร์</th>
      <th>อังคาร</th>
      <th>พุธ</th>
      <th>พฤหัสบดี</th>
      <th>ศุกร์</th>
      <th>เสาร์</th>
    </tr>
    </thead>
    <tbody>
    <tr class="text-center">
      <td v-for="(_, day) in [...Array.from({ length: 7 })]" :key="day">
        <span v-if="!repeatConfig[day] || !repeatConfig[day].length" class="text-muted font-italic">
          ไม่เปิดใช้งาน
        </span>
        <template v-else>
          <b-badge v-for="(_repeatConfig, i) in repeatConfig[day]" :key="i" variant="primary">
            {{ _repeatConfig.startTime }}-{{ _repeatConfig.endTime }}
            <span class="px-1" style="cursor: pointer" @click="onRemoveConfig(day, i)">x</span>
          </b-badge>
        </template>
      </td>
    </tr>
    </tbody>

    <tfoot>
    <tr>
      <td colspan="7" class="text-right">
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>วัน</b-input-group-text>
          </b-input-group-prepend>
          <b-select v-model="addForm.day" :options="daySelect" />

          <b-input-group-addon>
            <b-input-group-text>เวลา</b-input-group-text>
          </b-input-group-addon>

          <b-input-group-addon>
            <date-picker v-model="addForm.timeRange" type="time" format="HH:mm" range />
          </b-input-group-addon>

          <b-input-group-append>
            <b-button variant="success" :disabled="!addForm.timeRange" @click="onClickAdd">
              เพิ่ม <i class="uil uil-plus"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </td>
    </tr>
    </tfoot>
  </table>
</template>

<script>
import dayjs from 'dayjs';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'PromotionRepeatConfig',
  components: { DatePicker },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      repeatConfig: {},
      addForm: {
        day: '0',
        timeRange: null,
      },
      daySelect: [
        { text: 'อาทิตย์', value: 0 },
        { text: 'จันทร์', value: 1 },
        { text: 'อังคาร', value: 2 },
        { text: 'พุธ', value: 3 },
        { text: 'พฤหัสบดี', value: 4 },
        { text: 'ศุกร์', value: 5 },
        { text: 'เสาร์', value: 6 },
      ]
    }
  },
  watch: {
    value: {
      type: Object,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.repeatConfig = newValue;
        }
      }
    }
  },
  methods: {
    onClickAdd() {
      if (!this.addForm.timeRange) {
        return;
      }

      if (!this.repeatConfig[this.addForm.day]) {
        this.$set(this.repeatConfig, this.addForm.day, []);
      }

      this.repeatConfig[this.addForm.day].push({
        startTime: dayjs(this.addForm.timeRange[0]).format('HH:mm'),
        endTime: dayjs(this.addForm.timeRange[1]).format('HH:mm'),
      });

      this.addForm.timeRange = null;
      this.sortConfig()
      this.$emit('input', this.repeatConfig);
    },
    sortConfig() {
      Object
          .keys(this.repeatConfig)
          .forEach((day) => {
            this.repeatConfig[day] = this
                .repeatConfig[day]
                .sort((a, b) => {
                  const start = +a.startTime.replace(':', '')
                  const end = +b.startTime.replace(':', '')
                  return start - end;
                });
          })
    },
    onRemoveConfig(day, index) {
      this.repeatConfig[day].splice(index, 1);
    }
  }
}
</script>

<style>
.mx-input-wrapper, .mx-input {
  height: 100%
}
.mx-input {
  border-radius: 0;
  border: 1px solid #e2e7f1;
}
.mx-input:hover {
  border: 1px solid #e2e7f1;
}
</style>